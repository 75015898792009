import Repository from "@/api/repository";
import { baseUrl } from "./base";
export default {
    getProviderDataSsoUser(email) {
        return Repository.get(`${baseUrl}/user/externalIdentityProvider?email=${email}`);
    },
    getDomains() {
        return Repository.get(`${baseUrl}/tenant/domains`);
    },
    postToken(obj) {
        return Repository.post(`${baseUrl}/user/token`, obj);
    },
    loginExternal(obj) {
        return Repository.post(`${baseUrl}/user/login`, obj);
    },
    activate(obj) {
        return Repository.post(`${baseUrl}/user/activate`, obj);
    }
};
