import Projects from "./pages/projects/App.vue";
import Calendar from "./pages/calendar/App.vue";
import CalendarReservations from "./pages/calendarReservations/App.vue";
import Unauthorized from "./pages/unauthorized/App.vue";
import { PROJECTS_BIN_ROUTE_NAME } from "@/pages/projects/_providers/constants";
export const cloudRoutes = [
    {
        path: "/",
        name: "projects",
        component: Projects,
        meta: {
            title: "My Projects"
        }
    },
    {
        path: "/trash",
        name: PROJECTS_BIN_ROUTE_NAME,
        component: Projects,
        meta: {
            title: "Bin"
        },
        props: { isBinMode: true }
    },
    {
        path: "/project/:projectId/experiments",
        name: "experiments",
        component: () => import(
        /* webpackChunkName: "experiments" */ "./pages/experiments/App.vue"),
        props: true,
        meta: {
            title: "My Experiments"
        }
    },
    {
        path: "/trash/project/:projectId/experiments",
        name: "binExperiments",
        component: () => import(
        /* webpackChunkName: "experiments" */ "./pages/experiments/App.vue"),
        props: route => ({
            isBinMode: true,
            projectId: route.params.projectId
        }),
        meta: {
            title: "My Experiments in the bin"
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/exact/app",
        name: "exact-v0-app",
        component: () => import(
        /* webpackChunkName: "exact-v0-app" */ "./pages/exact/v0/appExact/components/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/exact",
        name: "exact-v0",
        component: () => import(
        /* webpackChunkName: "exact-v0" */ "./pages/exact/v0/exact/components/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/exact/v1",
        name: "exact-v1",
        component: () => import(/* webpackChunkName: "exact-v1" */ "./pages/exact/v1/App.vue"),
        props: true,
        meta: {
            title: window.GLOBAL_EXPERIMENT_NAME
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/exact/v2",
        name: "exact-v2",
        component: () => import(/* webpackChunkName: "exact-v2" */ "./pages/exact/v2/App.vue"),
        props: true,
        meta: {
            title: window.GLOBAL_EXPERIMENT_NAME
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/exact/v2/multicount",
        name: "exact-v2-multicount",
        component: () => import(
        /* webpackChunkName: "exact-v2-multicount" */ "./pages/exact/v2/App.vue"),
        props: true,
        meta: {
            title: window.GLOBAL_EXPERIMENT_NAME
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/exact/v2/organoid",
        name: "exact-v2-organoid",
        component: () => import(
        /* webpackChunkName: "exact-v2-organoid" */ "./pages/exact/v2/Organoid.vue"),
        props: true,
        meta: {
            title: window.GLOBAL_EXPERIMENT_NAME
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/well/:areaName",
        name: "well",
        component: () => import(/* webpackChunkName: "well" */ "./pages/well/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/Omni3",
        name: "well-plate",
        component: () => import(/* webpackChunkName: "well" */ "./pages/wellPlate/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/wellplate",
        name: "well-plate-react",
        component: () => import("./pages/wellPlate/wellPlateReact/App.vue"),
        props: true,
        meta: {
            title: window.GLOBAL_EXPERIMENT_NAME
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/lux2/v1",
        name: "lux",
        component: () => import(/* webpackChunkName: "lux" */ "./pages/lux/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/Settings",
        name: "settings",
        meta: {
            title: "Settings"
        },
        component: () => import(/* webpackChunkName: "settings" */ "./pages/settings/App.vue"),
        children: [
            {
                path: "Profile",
                name: "profile",
                meta: {
                    title: "Profile"
                },
                component: () => import(
                /* webpackChunkName: "settings-profile" */ "./pages/settings/account/UserDetails.vue")
            },
            {
                meta: {
                    title: "Organizations"
                },
                path: "Organizations",
                component: () => import(
                /* webpackChunkName: "settings-organizations" */ "./pages/settings/account/OgranizationsList.vue")
            },
            {
                meta: {
                    title: "Security"
                },
                path: "Security",
                component: () => import(
                /* webpackChunkName: "settings-security" */ "./pages/settings/account/ChangePassword.vue")
            },
            {
                meta: {
                    title: "Notifications"
                },
                path: "Notifications",
                component: () => import(
                /* webpackChunkName: "settings-notifications" */ "./pages/settings/notifications/NotificationSettings.vue")
            },
            {
                meta: {
                    title: "Profile"
                },
                path: "Organization/Profile",
                component: () => import(
                /* webpackChunkName: "settings-organizations-profile" */ "./pages/settings/organization/OrganizationProfile.vue")
            },
            {
                meta: {
                    title: "Privacy"
                },
                path: "Organization/Privacy",
                component: () => import(
                /* webpackChunkName: "settings-organizations-privacy" */ "./pages/settings/organization/privacy/index.vue")
            },
            {
                meta: {
                    title: "Admins"
                },
                path: "Organization/Admins",
                component: function () {
                    if (window.GLOBAL_SETTING_VIEW_ADMIN_TAB)
                        return import(
                        /* webpackChunkName: "settings-organizations-admin-users-create" */ "./pages/settings/organization/admins/Admins.vue");
                }
            },
            {
                meta: {
                    title: "Users"
                },
                path: "Organization/Users",
                name: "admin-users",
                component: function () {
                    if (window.GLOBAL_IS_ADMIN)
                        return import(
                        /* webpackChunkName: "settings-organizations-admin-users" */ "./pages/settings/admin/users/UserComponent.vue");
                    else if (window.GLOBAL_IS_ENTERPRISE_ADMIN)
                        return import(
                        /* webpackChunkName: "settings-organizations-users" */ "./pages/settings/user/UserComponent.vue");
                }
            },
            {
                meta: {
                    title: "Create User"
                },
                path: "Organization/Users/Create",
                component: function () {
                    if (window.GLOBAL_IS_ENTERPRISE_ADMIN)
                        return import(
                        /* webpackChunkName: "settings-organizations-admin-users-create" */ "./pages/settings/admin/users/createUser/CreateComponent.vue");
                }
            },
            {
                meta: {
                    title: "Edit User"
                },
                path: "Organization/Users/Edit/:userId",
                component: () => import(
                /* webpackChunkName: "settings-organizations-admin-users-edit" */ "./pages/settings/admin/users/editUser/EditComponent.vue")
            },
            {
                meta: {
                    title: "Devices"
                },
                path: "Organization/Devices",
                component: () => import(
                /* webpackChunkName: "settings-organizations-devices" */ "./pages/settings/device/devices/DeviceComponent.vue"),
                props: route => ({
                    /**
                     * The functionality for backlash editing is hidden for most users.
                     * It is available only to people that manually navigate to the page using this
                     * specific query parameter.
                     */
                    enableBacklashEdit: route.query.enableBacklashEdit === "true"
                })
            },
            {
                meta: {
                    title: "Device Edit"
                },
                path: "Organization/Devices/:firmwareNumber/Edit",
                component: function () {
                    if (window.GLOBAL_IS_ADMIN)
                        return import(
                        /* webpackChunkName: "settings-organizations-devices-edit" */ "./pages/settings/device/devices/DeviceEditPage.vue");
                    else
                        return import(
                        /* webpackChunkName: "settings-organizations-devices" */ "./pages/settings/device/devices/DeviceComponent.vue");
                }
            },
            {
                meta: {
                    title: "Device Details"
                },
                path: "Organization/Devices/Details",
                name: "device-details-page",
                component: function () {
                    if (window.GLOBAL_IS_ENTERPRISE_ADMIN)
                        return import(
                        /* webpackChunkName: "settings-organizations-devices-details" */ "./pages/settings/device/details/DeviceDetailsComponent.vue");
                }
            }
        ]
    },
    {
        path: "/Project/Create",
        name: "create-projects",
        component: () => import(
        /* webpackChunkName: "create-projects" */ "./pages/createProject/App.vue"),
        meta: {
            title: "Start new project"
        }
    },
    {
        path: "/Admin/Customers",
        name: "admin-customers",
        component: () => import(
        /* webpackChunkName: "admin-customers" */ "./pages/admin/customers/App.vue"),
        meta: {
            title: "Customers"
        }
    },
    {
        path: "/Admin/Distributors",
        name: "admin-distributors",
        component: () => import(
        /* webpackChunkName: "admin-distributors" */ "./pages/admin/deviceDistributors/App.vue"),
        meta: {
            title: "Distributors"
        }
    },
    {
        path: "/Admin/Notifications",
        name: "admin-Notifications",
        component: () => import(
        /* webpackChunkName: "admin-notifications" */ "./pages/admin/notifications/App.vue"),
        meta: {
            title: "Notifications"
        }
    },
    {
        path: "/Admin/Customers/:tenantId/Users",
        name: "admin-customers-users",
        component: () => import(
        /* webpackChunkName: "admin-customers-users" */ "./pages/admin/customerTenantUsers/users/App.vue"),
        props: route => ({
            tenantId: parseInt(route.params.tenantId)
        }),
        meta: {
            title: "Users"
        }
    },
    {
        path: "/Admin/TenantProject",
        name: "admin-tenantproject",
        component: () => import(
        /* webpackChunkName: "admin-tenantproject" */ "./pages/admin/customerTenantProjects/App.vue"),
        props: true,
        meta: {
            title: "Projects"
        }
    },
    {
        path: "/Calendar",
        name: "calendar",
        component: Calendar,
        meta: {
            title: "My Calendar"
        }
    },
    {
        path: "/HiddenCalendarReservations",
        name: "hiddenCalendar",
        component: CalendarReservations,
        meta: {
            title: "My Calendar"
        }
    },
    {
        path: "/Admin/TenantExperiment",
        name: "admin-tenantexperiment",
        component: () => import(
        /* webpackChunkName: "admin-tenantexperiment" */ "./pages/admin/customerTenantExperiments/App.vue"),
        props: true,
        meta: {
            title: "Experiments"
        }
    },
    {
        path: "/Admin/Customers/:tenantId/Devices",
        name: "admin-customers-devices",
        component: () => import(
        /* webpackChunkName: "admin-customers-devices" */ "./pages/admin/customerTenantDevices/devices/App.vue"),
        props: true,
        meta: {
            title: "Devices"
        }
    },
    {
        path: "/Admin/Customers/:tenantId/Devices/Details",
        name: "admin-customers-devices-details",
        component: () => import(
        /* webpackChunkName: "admin-customers-devices-details" */ "./pages/admin/customerTenantDevices/details/App.vue"),
        props: true,
        meta: {
            title: "Edit device"
        }
    },
    {
        path: "/Admin/Customers/:tenantId/Users/Details",
        name: "admin-customers-users-details",
        component: () => import(
        /* webpackChunkName: "admin-customers-users-details" */ "./pages/admin/customerTenantUsers/details/App.vue"),
        props: true,
        meta: {
            title: "User details"
        }
    },
    {
        path: "/Admin/Customers/:tenantId/Users/Edit/:userId",
        name: "admin-customers-users-edit",
        component: () => import(
        /* webpackChunkName: "admin-customers-users-edit" */ "./pages/admin/customerTenantUsers/edit/App.vue"),
        props: route => ({
            tenantId: parseInt(route.params.tenantId),
            userId: route.params.userId
        }),
        meta: {
            title: "Edit user"
        }
    },
    {
        path: "/License",
        name: "admin-license",
        component: () => import(
        /* webpackChunkName: "admin-license" */ "./pages/admin/license/App.vue"),
        props: true,
        meta: {
            title: "Licenses"
        }
    },
    {
        path: "/Admin/ScanPlans",
        name: "admin-data-plans",
        component: () => import(
        /* webpackChunkName: "admin-data-plan" */ "./pages/admin/dataPlans/dataPlan/App.vue"),
        meta: {
            title: "Devices Scan Plans"
        }
    },
    {
        path: "/Admin/ScanPlans/new",
        name: "admin-data-plans-new",
        meta: {
            title: "New data plan"
        },
        component: () => import(
        /* webpackChunkName: "admin-data-plan-new" */ "./pages/admin/dataPlans/newDataPlan/App.vue")
    },
    {
        path: "/Admin/ScanPlans/:tenantId/history/:deviceId",
        name: "admin-data-plans-history",
        meta: {
            title: "History data plans"
        },
        component: () => import(
        /* webpackChunkName: "admin-device-history-plans" */ "./pages/admin/dataPlans/history/App.vue")
    },
    {
        path: "/Admin/ScanPlans/:tenantId/history",
        name: "admin-tenant-data-plans-history",
        meta: {
            title: "Tenant scan plans history"
        },
        component: () => import(
        /* webpackChunkName: "admin-tenant-history-plans" */ "./pages/admin/dataPlans/tenantHistory/App.vue")
    },
    {
        path: "/Admin/ScanPlans/plans",
        name: "admin-data-plans-list",
        meta: {
            title: "Scan Plans"
        },
        component: () => import(
        /* webpackChunkName: "admin-data-plan-list" */ "./pages/admin/dataPlans/plans/App.vue")
    },
    {
        path: "/Device/AddLicense",
        name: "device-add-license",
        component: () => import(
        /* webpackChunkName: "add-license" */ "./pages/device/addLicense/App.vue"),
        props: true,
        meta: {
            title: "Add License"
        }
    },
    {
        path: "/Device/LicenseExpired",
        name: "device-license-expired",
        component: () => import(
        /* webpackChunkName: "add-license" */ "./pages/device/licenseExpired/App.vue"),
        props: true,
        meta: {
            title: "License Expired"
        }
    },
    {
        path: "/Account/Login",
        name: "account-login",
        component: () => import(
        /* webpackChunkName: "account-login" */ "./pages/auth/login/App.vue"),
        props: true,
        meta: {
            title: "Login"
        }
    },
    {
        path: "/Account/AppLogin",
        name: "account-app-login",
        component: () => import(
        /* webpackChunkName: "account-login" */ "./pages/auth/appLogin/App.vue"),
        props: true,
        meta: {
            title: "App Login"
        }
    },
    {
        path: "/Account/Register",
        name: "account-register",
        component: () => import(
        /* webpackChunkName: "account-register" */ "./pages/auth/register/App.vue"),
        props: true,
        meta: {
            title: "Register"
        }
    },
    {
        path: "/Account/updateAccount",
        name: "update-account",
        component: () => import(
        /* webpackChunkName: "update-account" */ "./pages/auth/updateAccount/App.vue"),
        props: true,
        meta: {
            title: "Update Profile"
        }
    },
    {
        path: "/Account/ForgotPassword",
        name: "account-forgot-password",
        component: () => import(
        /* webpackChunkName: "account-forgot-password" */ "./pages/auth/forgotPassword/App.vue"),
        props: true,
        meta: {
            title: "Forgot your password?"
        }
    },
    {
        path: "/Account/ForgotPasswordConfirmation",
        name: "account-forgot-password-confirmation",
        component: () => import(
        /* webpackChunkName: "account-forgot-password-confirmation" */ "./pages/auth/forgotPasswordConfirmation/App.vue"),
        props: true,
        meta: {
            title: "Forgot your password?"
        }
    },
    {
        path: "/Account/ResendActivation",
        name: "account-resend-activation",
        component: () => import(
        /* webpackChunkName: "account-resend-activation" */ "./pages/auth/reActivate/App.vue"),
        props: true,
        meta: {
            title: "Resend activation link"
        }
    },
    {
        path: "/Account/ResendActivationConfirmation",
        name: "account-resend-activation-confirmation",
        component: () => import(
        /* webpackChunkName: "account-resend-activation-confirmation" */ "./pages/auth/reActivateConfirmation/App.vue"),
        props: true,
        meta: {
            title: "Resend activation link"
        }
    },
    {
        path: "/Account/ResetPassword",
        name: "account-reset-password",
        component: () => import(
        /* webpackChunkName: "account-reset-password" */ "./pages/auth/resetPassword/App.vue"),
        props: true,
        meta: {
            title: "Reset password"
        }
    },
    {
        path: "/Account/ResetPasswordConfirmation",
        name: "account-reset-password-confirmation",
        component: () => import(
        /* webpackChunkName: "account-reset-password-confirmation" */ "./pages/auth/resetPasswordConfirmation/App.vue"),
        props: true,
        meta: {
            title: "Reset password confirmation"
        }
    },
    {
        path: "/Account/SelectTenant",
        name: "account-select-tenant",
        component: function () {
            var _a, _b, _c, _d;
            if (((_b = (_a = window.GLOBAL_VIEWBAG) === null || _a === void 0 ? void 0 : _a.MODEL) === null || _b === void 0 ? void 0 : _b.TenantPrompt) ||
                ((_d = (_c = window.GLOBAL_VIEWBAG) === null || _c === void 0 ? void 0 : _c.MODEL) === null || _d === void 0 ? void 0 : _d.NewDevice)) {
                return import(
                /* webpackChunkName: "account-select-tenant" */ "./pages/auth/appSelectTenant/App.vue");
            }
            return import(
            /* webpackChunkName: "account-select-tenant" */ "./pages/auth/selectTenant/App.vue");
        },
        props: true,
        meta: {
            title: "Organization selection"
        }
    },
    {
        path: "/Experiment/Start",
        name: "experiment-start",
        component: function () {
            if (window.GLOBAL_DEVICE_START === "exact") {
                return import(
                /* webpackChunkName: "start-exact-experiment" */ "./pages/start/exact/App.vue");
            }
            else if (window.GLOBAL_DEVICE_START === "lux") {
                return import(
                /* webpackChunkName: "start-lux2-experiment" */ "./pages/start/lux2/App.vue");
            }
            return import(
            /* webpackChunkName: "start-omni-experiment" */ "./pages/start/omni/experiment/App.vue");
        },
        meta: {
            title: "Start new experiment"
        }
    },
    {
        path: "/Signup",
        name: "signup",
        component: () => import(/* webpackChunkName: "signup" */ "./pages/auth/signup/App.vue"),
        props: true,
        meta: {
            title: "Signup"
        }
    },
    {
        path: "/Account/Activate",
        name: "account-activate",
        component: () => import(
        /* webpackChunkName: "account-activate" */ "./pages/auth/activate/App.vue"),
        props: true,
        meta: {
            title: "Activate your account"
        }
    },
    {
        path: "/Experiment/StartExact",
        name: "experiment-start-exact",
        component: () => import(
        /* webpackChunkName: "start-exact-experiment" */ "./pages/start/exact/App.vue"),
        props: true
    },
    {
        path: "/Experiment/StartLux2",
        name: "experiment-start-lux2",
        component: () => import(
        /* webpackChunkName: "start-lux2-experiment" */ "./pages/start/lux2/App.vue"),
        props: true
    },
    {
        path: "/Lux3/start",
        name: "experiment-start-lux3",
        component: () => import(
        /* webpackChunkName: "start-lux3-experiment" */ "./pages/start/lux3/App.vue"),
        props: true,
        meta: {
            title: "Start new experiment"
        }
    },
    {
        path: "/Omni3/start",
        name: "experiment-start-omni3",
        component: () => import(
        /* webpackChunkName: "start-omni3-experiment" */ "./pages/start/omni3/App.vue"),
        props: true,
        meta: {
            title: "Start new experiment"
        }
    },
    {
        path: "/Experiment/StartOmni",
        name: "experiment-start-omni",
        component: () => import(
        /* webpackChunkName: "start-omni-experiment" */ "./pages/start/omni/experiment/App.vue"),
        props: true
    },
    {
        path: "/Experiment/AppendScanSelection",
        name: "scan-append",
        component: () => import(
        /* webpackChunkName: "append-omni-scan"*/ "./pages/start/omni/scan/App.vue"),
        props: true,
        meta: {
            title: "Append scan to experiment"
        }
    },
    {
        path: "/Admin/Tenant/Manage",
        name: "admin-manage-tenant",
        component: () => import(
        /* webpackChunkName: "admin-manage-tenant" */ "./pages/admin/manageTenant/App.vue")
    },
    {
        path: "/project/:projectId/experiments/:experimentId/Lux3/v1",
        name: "lux3-v1",
        component: () => import(/* webpackChunkName: "lux3-v1" */ "./pages/lux3/v1/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/Lux3/v2",
        name: "lux3-v2",
        component: () => import(/* webpackChunkName: "lux3-v2" */ "./pages/lux3/v2/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/experiments/:experimentId/Omni3/v1/well",
        name: "omni3-v1-well",
        component: () => import(
        /* webpackChunkName: "omni3-v1-well" */ "./pages/omni3Well/App.vue"),
        props: true,
        meta: {
            title: ""
        }
    },
    {
        path: "/project/:projectId/unsubscribe",
        name: "unsubscribe",
        component: () => import(
        /* webpackChunkName: "unsubscribe" */ "./pages/projects/unsubscribe/unsubscribe.vue"),
        meta: {
            title: "Unsubscribe Project"
        }
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: Unauthorized,
        meta: {
            title: "Unauthorized"
        }
    },
    {
        path: "/axis/experiments",
        name: "axis-experiments",
        component: () => import(
        /* webpackChunkName: "exact-v0" */ "./pages/ecosystem/ExperimentList.vue"),
        meta: {
            title: "Experiments"
        }
    },
    {
        path: "/Account/SSO/Login",
        name: "account-sso-login",
        component: () => import(
        /* webpackChunkName: "account-login" */ "./pages/auth/ssoLogin/App.vue"),
        props: true,
        meta: {
            title: "SSO Login"
        }
    }
];
