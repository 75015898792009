import { computed, defineComponent, ref } from "vue";
import { LayoutTypes } from "@/shared/providers/withLayoutType/types/LayoutTypes";
import { ExperimentModalType } from "@/pages/experiments/_providers/types/enums/ExperimentsModalEnums";
import BProjectsShare from "@/pages/projects/BProjectsShare.vue";
import { useProjects } from "@/shared/providers/withProjects/withProjects";
import { useProjectActions } from "@/shared/providers/withProjects/useProjectActions/useProjectActions";
import { useDialogPlugin } from "@axisvue/dialogs";
import { useLayoutType } from "@/shared/providers/withLayoutType/withLayoutType";
import { INITIAL_PAGE } from "@/shared/providers/withProjects/constants/ProjectsInitialPage";
import { DEFAULT_ITEMS_PER_PAGE } from "@/shared/providers/withProjects/constants/DefaultItemsPerPage";
import BProjectsListCard from "./BProjectsListCard.vue";
import BProjectsListItemEdit from "./BProjectsListItemEdit.vue";
import BProjectsListRow from "./BProjectsListRow.vue";
import ProjectExperimentsModal from "@/pages/experiments/ProjectExperimentsModal.vue";
import { useToastr } from "@/globals/plugins/toastr/useToastr";
import { useBinMode } from "@/shared/providers/withBinMode/withBinMode";
export default defineComponent({
    components: {
        BProjectsListCard,
        BProjectsListRow,
        BProjectsListItemEdit,
        BProjectsShare,
        ProjectExperimentsModal
    },
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    setup() {
        const toastr = useToastr();
        const { editProject, inviteUsersToProject, followProject, unfollowProject, leaveProject, restoreProject, deleteProject } = useProjectActions();
        const { editItemFromProjectsList, followItemFromProjectsList, unfollowItemFromProjectsList, fetchProjects, deleteItemFromProjectsList, moveProjectToBin } = useProjects();
        const { fetchDeletedExperiments } = useBinMode();
        const { layoutType } = useLayoutType();
        const dialog = useDialogPlugin();
        const showEditModal = ref(false);
        const showShareModal = ref(false);
        const showLeaveModal = ref(false);
        const unableLeaveProjectMsg = `You cannot leave the project as it still contains experiments you created.
        Please move the experiments you own to another project or permanently delete them from the bin.`;
        const component = computed(() => layoutType.value === LayoutTypes.LIST
            ? BProjectsListRow
            : BProjectsListCard);
        const closeShareModal = () => {
            showShareModal.value = false;
        };
        const share = async () => {
            showShareModal.value = true;
        };
        const closeEditModal = () => {
            showEditModal.value = false;
        };
        const edit = () => {
            showEditModal.value = true;
        };
        const updateProject = async (project) => {
            try {
                await editProject(project);
                editItemFromProjectsList(project);
                closeEditModal();
                toastr.success("Successfully changed the project's details.");
            }
            catch (error) {
                toastr.error(error);
            }
        };
        const inviteUser = async (data) => {
            try {
                await inviteUsersToProject(data);
                if (data.emails.length) {
                    let user = data.emails.length === 1 ? " user." : " users.";
                    toastr.success("An invitation mail has been sent to " +
                        data.emails.length + user);
                }
                else {
                    toastr.success("An invitation mail has been sent to " + data.emails[0]);
                }
                showShareModal.value = false;
            }
            catch (error) {
                toastr.error(error);
            }
        };
        const restoreHandler = (project) => {
            return dialog
                .confirm({
                title: "Restore project",
                message: "The project and its experiments will no longer be deleted.",
                okButtonText: "Restore"
            })
                .then(confirmed => {
                if (confirmed) {
                    return restoreProject(project.id)
                        .then(() => {
                        fetchDeletedExperiments();
                        fetchProjects({
                            page: INITIAL_PAGE,
                            pageSize: DEFAULT_ITEMS_PER_PAGE[0],
                            bin: true
                        });
                        toastr.success("Successfully restored the project");
                    })
                        .catch(() => {
                        toastr.error("Something went wrong while restoring that project.");
                    });
                }
            });
        };
        /**
         * Function which moves project to the bin.
         * @param {any} project the project that needs to be moved to the bin
         */
        const moveHandler = (project) => {
            return moveProjectToBin(project.id)
                .then(() => {
                deleteItemFromProjectsList(project.id);
                toastr.success("Successfully moved project to the bin.");
                fetchProjects({
                    page: INITIAL_PAGE,
                    pageSize: DEFAULT_ITEMS_PER_PAGE[0]
                });
                fetchDeletedExperiments();
            })
                .catch(() => {
                toastr.error("Something went wrong when moving this project to the bin.");
            });
        };
        /**
         * Function that alerts user if he wants to delete a selected project.
         * If confirm is selected, a project deletion request is sent.
         * @param {any} project the project that needs to be deleted
         */
        const deleteHandler = (project) => {
            return dialog
                .confirm({
                title: "Deleting Project",
                message: `Are you sure you want to delete that project?
          Your project will be deleted permanently`,
                okButtonText: "DELETE"
            })
                .then(confirmed => {
                if (confirmed) {
                    return deleteProject(project.id)
                        .then(() => {
                        deleteItemFromProjectsList(project.id);
                        fetchDeletedExperiments();
                        toastr.success("Successfully deleted project.");
                    })
                        .catch(() => {
                        toastr.error("Something went wrong when deleting this project.");
                    });
                }
            })
                .catch(error => {
                toastr.error(error);
            });
        };
        const follow = async (project) => {
            try {
                await followProject(project.id);
                followItemFromProjectsList(project.id);
                toastr.success("Successfully following the project.");
            }
            catch (error) {
                toastr.error(error);
            }
        };
        const unfollow = async (project) => {
            try {
                await unfollowProject(project.id);
                unfollowItemFromProjectsList(project.id);
                toastr.success("Successfully unfollowing the project.");
            }
            catch (error) {
                toastr.error(error);
            }
        };
        const leave = (project) => {
            return dialog
                .confirm({
                title: "Leaving Project",
                message: "The project will NOT be deleted but you will NOT be able to see " +
                    "it anymore. To see the project again you will need to to be invited to it. " +
                    "\n\n Are you sure you want to leave this project?",
                okButtonText: "Confirm"
            })
                .then(confirmed => {
                if (confirmed) {
                    return leaveProject(project.id)
                        .then(() => {
                        deleteItemFromProjectsList(project.id);
                        toastr.success("Successfully left the project.");
                        fetchProjects({
                            page: INITIAL_PAGE,
                            pageSize: DEFAULT_ITEMS_PER_PAGE[0]
                        });
                    })
                        .catch(error => {
                        if (error === 469) {
                            showLeaveModal.value = true;
                        }
                        else {
                            toastr.error("Something went wrong when leaving this project.");
                        }
                    });
                }
            })
                .catch(() => {
                toastr.error("Something went wrong when leaving this project.");
            });
        };
        return {
            component,
            showEditModal,
            showShareModal,
            showLeaveModal,
            unableLeaveProjectMsg,
            ExperimentModalType,
            closeEditModal,
            closeShareModal,
            deleteHandler,
            edit,
            follow,
            inviteUser,
            leave,
            moveHandler,
            restoreHandler,
            share,
            unfollow,
            updateProject
        };
    }
});
