<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="w-full justify-between bg-brand-navy px-6 pt-5 md:flex md:pt-0">
    <div class="h-12 self-center">
      <router-link to="/">
        <img
          :src="`${publicPath}/assets/logo/Axion_Logo-01.svg`"
          class="h-full"
        />
      </router-link>
    </div>

    <div
      class="group absolute top-0 right-0 mt-6 mr-5 block cursor-pointer md:hidden"
      @click="showMobileMenu = !showMobileMenu"
    >
      <div class="my-1 h-1 w-6 bg-blue-100 group-hover:bg-white"></div>
      <div class="my-1 h-1 w-6 bg-blue-100 group-hover:bg-white"></div>
      <div class="my-1 h-1 w-6 bg-blue-100 group-hover:bg-white"></div>
    </div>
    <div class="mt-5 self-center md:mt-0">
      <div
        class="items-baseline text-center md:ml-0 md:mt-0 md:flex"
        :class="{ hidden: !showMobileMenu }"
      >
        <switch-tenant-drawer
          v-if="showTenants"
          :available-tenants="availableTenants"
          :default-tenant="defaultTenantId"
          @close="closeDrawer"
        />
        <div class="form-layout">
          <div v-for="u in urls" :key="u.name" class="self-center md:mb-0">
            <div class="border-white-400 border-t py-2 md:border-none">
              <!-- is needed to refresh the page when you are on the nth page in the table -->
              <a
                v-if="
                  $route.name === 'projects' &&
                  u.name === 'Projects' &&
                  $route.query.page
                "
                :href="u.link"
                class="router-link-exact-active router-link-active mr-0 border-b-2 border-transparent py-2 text-lg font-normal text-blue-100 no-underline md:ml-8"
              >
                Projects
              </a>
              <router-link
                v-else
                class="mr-0 border-b-2 border-transparent py-2 text-lg font-normal text-blue-100 no-underline md:ml-8"
                :to="u.link"
                >{{ u.name }}
              </router-link>
            </div>
          </div>
          <div class="border-white-400 border-t md:border-none">
            <div
              id="beamerButton"
              data-beamer-click="true"
              class="cursor-pointer select-none border-b-2 border-transparent py-2 text-lg font-normal text-blue-100 no-underline md:ml-8"
            >
              What's new?
            </div>
          </div>
        </div>
        <portal to="popups">
          <button
            v-if="showAccountDetails"
            class="fixed absolute top-0 right-0 left-0 bottom-0 h-full w-full"
            @click="showAccountDetails = false"
          ></button>
          <transition name="slide">
            <div
              v-if="showAccountDetails"
              class="dropdown absolute top-0 right-0 mt-20 mr-1 rounded-lg bg-white p-4 shadow-md"
              style="z-index: 9999"
            >
              <a
                id="dropdown-settings"
                class="h-25 text-grey-darkest block flex cursor-pointer whitespace-nowrap bg-white px-4 py-3 hover:bg-gray-200"
                @click="settings"
              >
                <div class="cursor-pointer text-gray-500">
                  <b-icon name="settings" class="mr-4 h-12 w-12" />
                </div>
                <div
                  class="text-md flex justify-end self-center text-center font-semibold text-gray-500"
                >
                  Settings
                </div>
              </a>
              <a
                v-if="availableTenants.length > 0"
                id="dropdown-switch"
                class="h-25 text-grey-darkest block flex cursor-pointer whitespace-nowrap bg-white px-4 py-3 hover:bg-gray-200"
                @click="showTenants = true"
              >
                <div class="cursor-pointer text-gray-500">
                  <b-icon name="repeat" class="mr-4 h-12 w-12" />
                </div>
                <div
                  class="text-md flex justify-end self-center text-center font-semibold text-gray-500"
                >
                  Switch organization
                </div>
              </a>
              <a
                id="dropdown-logout"
                class="h-25 text-grey-darkest block flex cursor-pointer whitespace-nowrap bg-white px-4 py-3 hover:bg-gray-200"
                @click="logout"
              >
                <svg
                  class="mr-4 h-12 w-12 cursor-pointer"
                  width="80%"
                  height="100%"
                  viewBox="0 -2 5 9"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                  "
                >
                  <path
                    id="Icon_open-account-logout"
                    xmlns="http://www.w3.org/2000/svg"
                    d="M3.878,0l0,0.776l-3.102,0l0,3.877l3.102,0l0,0.776l-3.878,0l0,-5.429l3.878,0Zm0.775,1.551l1.551,1.163l-1.551,1.164l0,-0.776l-3.102,0l0,-0.775l3.102,0l0,-0.776Z"
                  />
                </svg>
                <div
                  class="text-md flex justify-end self-center text-center font-semibold text-gray-500"
                >
                  Sign out
                </div>
              </a>
            </div>
          </transition>
        </portal>
        <div
          class="border-white-400 border-t md:hidden md:border-none"
          :class="{ hidden: !showMobileMenu }"
        >
          <a
            class="block flex cursor-pointer justify-center border-b-2 border-transparent px-4 py-3 text-lg font-normal text-blue-100"
            @click="settings"
          >
            <div class="cursor-pointer">
              <b-icon name="settings" class="mr-1 h-8 w-8" />
            </div>
            <div class="flex justify-end self-center text-center text-lg">
              Settings
            </div>
          </a>
          <div
            class="border-white-400 border-t md:hidden md:border-none"
            :class="{ hidden: !showMobileMenu }"
          >
            <a
              class="block flex cursor-pointer justify-center border-b-2 border-transparent px-4 py-3 text-lg font-normal text-blue-100"
              @click="logout"
            >
              <svg
                class="mr-1 h-8 w-8 cursor-pointer"
                width="80%"
                height="100%"
                viewBox="0 -2 5 9"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
              >
                <path
                  id="Icon_open-account-logout"
                  xmlns="http://www.w3.org/2000/svg"
                  d="M3.878,0l0,0.776l-3.102,0l0,3.877l3.102,0l0,0.776l-3.878,0l0,-5.429l3.878,0Zm0.775,1.551l1.551,1.163l-1.551,1.164l0,-0.776l-3.102,0l0,-0.775l3.102,0l0,-0.776Z"
                  style="fill: #fff9f9; fill-rule: nonzero"
                />
              </svg>
              <div
                class="flex justify-end self-center text-center text-lg text-blue-100"
              >
                Sign out
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <button
      :class="`btn outline-none flex hidden h-12 w-12 items-center justify-center self-center rounded-full border-2 border-white text-xl text-white shadow-none focus:ring-0 md:block`"
      @click="showAccountDetails = !showAccountDetails"
    >
      <span v-if="shortLetters">{{ shortLetters }}</span>
      <b-icon v-else name="account" class="h-10 w-10 p-1 text-xl" />
    </button>
  </nav>
</template>
<script>
import axios from "axios";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  computed,
  onMounted
} from "vue";
import { publicPath } from "@/constants";
import router from "@/router";
import { useBreadCrumbs } from "@/shared/components/navigation/BreadCrumbs/withBreadCrumbs";
import SwitchTenantDrawer from "./SwitchTenantDrawer.vue";
import { useLoggedInUser } from "@/shared/providers/withLoggedInUser/withLoggedInUser";

export default defineComponent({
  components: {
    SwitchTenantDrawer
  },
  setup() {
    const instance = getCurrentInstance();
    const account = ref(null);
    const urls = ref(window.GLOBAL_NAVIGATION_URLS);
    const userName = ref(window.GLOBAL_CURRENT_USER_FULL_NAME);
    const globalAdmin = ref(false);
    const isAdmin = ref(window.GLOBAL_IS_ADMIN || false);
    const showMobileMenu = ref(false);
    const selectedTenantId = ref(null);
    const showAccountDetails = ref(false);
    const showTenants = ref(false);
    const shortLetters = ref(null);
    const showAlert = ref(false);
    const alertMessage = ref("");
    const availableTenants = ref([]);
    const { setBreadCrumbs } = useBreadCrumbs();
    const {
      loggedInUser,
      isAdminOrganization,
      isGlobalAdmin,
    } = useLoggedInUser();

    const defaultTenantId = computed(() => loggedInUser.value.defaultTenantId);

    function fetchAvailableTenants() {
      axios
        .get(
          `${instance.proxy.$endpoint.manage}/user/availableTenants?includingCurrentTenant=false`
        )
        .then(r => {
          if (r.status === 200) {
            availableTenants.value = r.data;
            isAdminOrganization.value = isAdmin.value;
          }
        })
        .then(() => {
          isGlobalAdmin.value =
            isAdmin.value && availableTenants.value.length === 0;
        });
    }

    function addCalendarPath() {
      this.$router.push({ name: "calendar" });
      setBreadCrumbs([
        {
          name: "Calendar"
        }
      ]);
    }

    function logout() {
      axios.post("/Account/Logoff", {}).then(() => {
        window.location.href = window.location.origin;
        localStorage.removeItem("tenantId");
      });
    }

    function settings() {
      if (router.currentRoute.name !== "profile")
        router.push({ name: "profile" });
    }

    function closeDrawer() {
      showTenants.value = false;
      selectedTenantId.value = null;
      showAccountDetails.value = false;
    }

    onMounted(() => {
      fetchAvailableTenants();
    });

    if (userName.value) {
      if (userName.value.indexOf("/") > -1) {
        shortLetters.value = userName.value.split("/ ")[1].match(/\b(\w)/g);
        shortLetters.value =
          shortLetters.value[0].toUpperCase() +
          shortLetters.value[shortLetters.value.length - 1].toUpperCase();
      } else {
        shortLetters.value = userName.value.match(/\b(\w)/g);
        shortLetters.value =
          shortLetters.value[0].toUpperCase() +
          shortLetters.value[shortLetters.value.length - 1].toUpperCase();
      }
    }

    return {
      loggedInUser,
      defaultTenantId,
      account,
      urls,
      userName,
      isAdmin,
      showMobileMenu,
      selectedTenantId,
      showAccountDetails,
      showTenants,
      shortLetters,
      showAlert,
      alertMessage,
      availableTenants,
      addCalendarPath,
      logout,
      settings,
      closeDrawer,
      publicPath,
      globalAdmin
    };
  }
});
</script>

<style lang="scss" scoped>
$height: 80px;

svg:hover {
  circle {
    stroke: #ffffff;
    stroke-width: 2px;
  }
}

a#dropdown-settings:hover,
a#dropdown-switch:hover,
a#dropdown-logout:hover {
  div {
    color: #071624;
  }

  svg path {
    fill: #071624;
    color: #071624;
  }
}

path {
  fill: #c9c9c9;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

svg text::selection {
  background: none;
}

nav {
  min-height: $height;
}

.header-image {
  z-index: 0;
  width: 1916px;
  // height: 105px;
  max-width: 1916px;
}

.form-layout {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-template-areas:
    "label input"
    "empty errors";
}

@media screen and (max-width: 767px) {
  .layout {
    grid-template-areas:
      "label label"
      "input input";
  }
  .form-layout {
    grid-template-columns: 1fr;
    grid-template-areas:
      "label"
      "input"
      "errors";
    grid-row-gap: 0.3rem;
  }
}

.slide-enter-active,
.slide-leave-active {
  animation: main 0.2s;
  transform-origin: top right;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}

@keyframes main {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
