import { ref } from "vue";
import { setupProvider } from "@/globals/setupProvider";
import RepositoryFactory from "@/api/repositoryFactory";
const SsoAuthenticationRepository = RepositoryFactory.ssoAuthenticationRepository;
/**
 * Provider that handles global state for scan plans.
 */
export function withSSO(loggedInUser) {
    const allowedDomains = ref([]);
    const isSsoUser = ref(false);
    const getAllowedDomains = async () => {
        try {
            const ssoStatus = await SsoAuthenticationRepository.getProviderDataSsoUser(loggedInUser.value.email);
            if (ssoStatus.status === 200) {
                isSsoUser.value = true;
                const response = await SsoAuthenticationRepository.getDomains();
                allowedDomains.value = response.data;
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    return {
        allowedDomains,
        getAllowedDomains,
        isSsoUser
    };
}
export const { provide: provideSSO, use: useSSO } = setupProvider();
